import { ShippingItem } from "./../@types/ShippingItem";
import { Address } from "./../@types/Address";

export const listOfCarriers = [
  {
    label: "Canada Post",
    value: "CanadaPost",
  },
  // {
  //     label: "FedEx",
  //     value: "fedex"
  // }
  {
    label: "UPS",
    value: "ups",
  },
  {
    label: "USPS",
    value: "usps",
  },
  {
    label: "Canpar",
    value: "CANPAR",
  },
  {
    label: "Purolator",
    value: "PUROLATOR",
  },
  {
    label: "ShipVista Connect",
    value: "SHIPVISTACONNECT",
  },
];

export const initialAddress: Address = {
  nickname: "",
  name: "",
  companyName: "",
  streetAddress: "",
  streetAddress2: "",
  city: "",
  country: "Canada",
  countryCode: "CA",
  state: "Ontario",
  stateCode: "ON",
  postalCode: "",
  phone: "",
  email: "",
  isDefault: true,
  isResidence: false,
  isSave: true,
  formattedAddress: "",
};

export const defaultShippingItems: ShippingItem[] = [
  {
    quantity: "1",
    length: "",
    width: "",
    height: "",
    weight: "",
    insurance: "",
    InsuranceType: {
      id: 0,
      code: "NONE",
      title: "No Insurance",
    },
    package: {
      code: "CUSTOM_PACKAGE",
      carrier: "",
      name: "Custom Dimensions",
    },
    disableDimensions: false,
    disableWeight: false,
    isDangerous: false,
    description: "",
    freightClass: "",
    packagingTypeLTL: {
      name: "",
      code: "",
    },
    vehicleType: {
      name: "",
      code: "",
    },
    typeOfGoods: {
      name: "",
      code: "",
    },
  },
];

export const acceptedFilesTypes = [
  {
    format: "application/pdf",
    name: "PDF",
  },
  {
    format: "application/msword",
    name: "DOC",
  },
  {
    format:
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    name: "DOCX",
  },
  {
    format: "image/jpeg",
    name: "JPG",
  },
  {
    format: "image/png",
    name: "PNG",
  },
];

export const phoneRegExp = /^\d{10}$/g;

export const dashboardUrl = process.env.NEXT_PUBLIC_DASHBOARD_URL;

export const googleMapsApiKey = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY;

export const shipvistaApiURL = process.env.NEXT_PUBLIC_API_URL;

export const googleAnalyticsId = process.env.NEXT_PUBLIC_GA_ID;

export const googleTagManagerId = process.env.NEXT_PUBLIC_GTM_ID;

export const fbPixelId = process.env.NEXT_PUBLIC_FACEBOOK_PIXEL;

export const tikTokPixelId = process.env.NEXT_PUBLIC_TIKTOK_PIXEL;

export const bingTrackingId = process.env.NEXT_PUBLIC_BING_TRACKING;

export const microsoftClarityId = process.env.NEXT_PUBLIC_MICROSOFT_CLARITY;

export const dataDogAppId = process.env.NEXT_PUBLIC_DATADOG_APP_ID ?? "";

export const dataDogClientToken =
  process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN ?? "";

export const dataDogSiteName = process.env.NEXT_PUBLIC_DATADOG_SITE_NAME ?? "";

export const dataDogServiceName = process.env.NEXT_PUBLIC_DATADOG_SERVICE ?? "";

export const dataDogEnv = process.env.NEXT_PUBLIC_DATADOG_ENV ?? "n_dev";

export const dataDogVersion =
  process.env.NEXT_PUBLIC_DATADOG_VERSION ?? "1.0.0";

export const routerEvents = {
  logout: "logout",
};
