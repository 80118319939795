export const logo =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/ShipVistaLogo.png";
export const smallLogo =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/logo192.png";
export const logo512 = "https://www.shipvista.com/logo512.png";
export const heroBackground =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/airmiles/airmiles-hero-banner.webp";
export const aboutImg1 =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/landing-page/circles.png";
export const heroImg1 =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/airmiles/white-air-miles-logo.webp";
export const shopifyRating =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/landing-page/shopify-review.png";
export const wooComRating =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/landing-page/woocommerce-review.png";
export const integrationImg =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/landing-page/compound-logo-3.png";
export const shippingLableImg =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/landing-page/shipping-label-full.png";
export const trackingImg =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/landing-page/phone-track-full.png";
export const streamLineImg =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/landing-page/truck-illus-logo.png";
export const img24x7img =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/landing-page/icons/save-hours.png";
export const multiCarriersImg =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/landing-page/icons/ship-with-carriers.png";
export const lowShippingCostImg =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/landing-page/icons/returns.png";
export const easyToUseImg =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/landing-page/icons/lower-cost.png";
export const uspsImg =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/landing-page/usps-offer.png";
export const upsImg =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/landing-page/ups-offer.png";
export const cpImg =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/landing-page/capost-offer.png";
export const canparImg =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/landing-page/canpar-offer.png";
export const technologyPartners =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/landing-page/tech-partners.png";
export const fulFillmentPartners =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/landing-page/fulfillment-partners-partial.png";
export const stripeLandingPageImg =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/stripe/cards-stripe.png";

export const shipvistaLargeLogo =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/shipVistaMainLogo.png";

export const shofifyLogo =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/technology/shopify-logo.png";
export const magentoLogo =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/technology/magento-logo.png";
export const woocommerceLogo =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/technology/woocommerce-logo.png";

export const refCardImg1 =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/landing-page/identification.png";
export const refCardImg2 =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/landing-page/megaphone.png";
export const refCardImg3 =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/landing-page/cash.png";

export const walmartLogo =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/technology/walmart-logo.png";
export const upsHeaderImg =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/ups/logo.svg";
export const upsSlider1Img =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/ups/shipping/ups-shipping-1.png";
export const upsSlider2Img =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/ups/shipping/ups-shipping-2.png";
export const upsSlider3Img =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/ups/shipping/ups-shipping-3.png";
export const upsSlider4Img =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/ups/shipping/ups-shipping-4.png";

export const planImg1 =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/subscription/icons/lab-icon.svg";
export const planImg2 =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/subscription/icons/core-icon.svg";
export const planImg3 =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/subscription/icons/advan-icon.svg";

export const upsConnect =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/ups-connect.png";
export const upsCarrierList =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/carrier-list.png";
export const upsConnectCarrierButton =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/carrier-connect-button.png";
export const upsConnectCarrier =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/ups-assets/connect-carrier-page.PNG";

//become a partner
export const bapGr1Img =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/becomeAPartner/becomeAPartner.png";
export const bapgr2Img1 =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/becomeAPartner/tech-icon_x999ed.webp";
export const bapgr2Img2 =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/becomeAPartner/fulfillment-icon_w86w8u.webp";

// become an affiliate
export const baaImg1 =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/becomeAnAffiliate/becomeAnAffiliate.png";

//overview
export const oImgHero =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/developers/image-removebg-preview_vhp1pz.png";
export const oImg1 =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/developers/UPS.png";
export const oImg2 =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/developers/CanadaPost.png";
export const oImg3 =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/usps-assets/download_zllryx.png";
export const oImg4 =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/developers/more-icon.png";
export const oImg5 =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/developers/reliability-graphic.png";

//welcome offer
export const woImg1 =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/about/logo-illustration.png";

//about
export const aboutTopArt =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/about/top-art.png";
export const aboutPartnersImage =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/about/partners-image.png";
export const aboutLogoIllustration =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/about/logo-illustration.png";
export const aboutIllustration =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/about/Illustration.png";
export const carrierLogosUrl =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/carrierLogos/";

//airmiles givaway
export const amgImg1 =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/airmiles/contest-illustration-fixed.png";
export const amgImg2 =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/airmiles/air-miles-card-illustration.png";

// newsroom
export const newsroomBanner =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/newsroom/banner-image.jpg";
export const newsroomAnnouncements =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/newsroom/announcements/";

//blog
export const blogPlaceholder =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/media/image-na-banner.png";
export const blogPerson =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/media/person-image.png";

//about us
export const aboutUsImg1 =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/about/about-us-hero_zgjidv.webp";
export const aboutUsImg2 =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/about/logo-illustration.png";
export const aboutUsImg3 =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/about/partners-image.png";
export const aboutUsImg4 =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/about/about-us-footer_v6tvnp.webp";

//careers
export const carImg1 =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/careers/image1.jpg";
export const carImg2 =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/careers/image2.jpg";

export const airmilesSmallLogo =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/airmiles/air-miles-logo-no-text.png";

export const purolatorShipNowImages = {
  "how-to-do-packaging":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/purolator/how-to-do-packaging.png",
  chooseTheRightBox:
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/purolator/chooseTheRightBox.png",
  ProtectYourItems:
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/purolator/ProtectYourItems.jpg",
  SealThePackageSecurely:
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/purolator/SealThePackageSecurely.jpg",
  LabelYourPackageCorrectly:
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/purolator/LabelYourPackageCorrectly.jpg",
};

export const intelcomImages = {
  "header-img":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/intelcom/image_001.png",
  "intelcom-2":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/intelcom/image_002.png",
  "intelcom-6":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/intelcom/image_006.png",
  "intelcom-7":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/intelcom/image_007.png",
  "intelcom-15":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/intelcom/image_015.png",
};

export const purolatorImages = {
  Ship_Vista_Page_Refresh_EN_4_01:
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/purolator/Ship_Vista_Page_Refresh_EN_4_01.png",
  Ship_Vista_Page_Refresh_EN_4_09:
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/purolator/Ship_Vista_Page_Refresh_EN_4_09.png",
  Ship_Vista_Page_Refresh_EN_4_10:
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/purolator/Ship_Vista_Page_Refresh_EN_4_10.png",
  Ship_Vista_Page_Refresh_EN_4_11:
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/purolator/Ship_Vista_Page_Refresh_EN_4_11.png",
  Ship_Vista_Page_Refresh_EN_4_12:
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/purolator/Ship_Vista_Page_Refresh_EN_4_12.png",
  Ship_Vista_Page_Refresh_EN_4_13:
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/purolator/Ship_Vista_Page_Refresh_EN_4_13.png",
  Ship_Vista_Page_Refresh_EN_4_14:
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/purolator/Ship_Vista_Page_Refresh_EN_4_14.png",
  Ship_Vista_Page_Refresh_EN_4_15:
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/purolator/Ship_Vista_Page_Refresh_EN_4_15.png",
  Ship_Vista_Page_Refresh_EN_4_30:
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/purolator/Ship_Vista_Page_Refresh_EN_4_30.png",
  Ship_Vista_Page_Refresh_EN_4_31:
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/purolator/Ship_Vista_Page_Refresh_EN_4_31.png",
  Ship_Vista_Page_Refresh_EN_4_32:
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/purolator/Ship_Vista_Page_Refresh_EN_4_32.png",
  Ship_Vista_Page_Refresh_EN_4_33:
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/purolator/Ship_Vista_Page_Refresh_EN_4_33.png",
  Ship_Vista_Page_Refresh_EN_4_53:
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/purolator/Ship_Vista_Page_Refresh_EN_4_53.png",
  Ship_Vista_Page_Refresh_EN_4_54:
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/purolator/Ship_Vista_Page_Refresh_EN_4_54.png",
  Ship_Vista_Page_Refresh_EN_4_55:
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/purolator/Ship_Vista_Page_Refresh_EN_4_55.png",
  Ship_Vista_Page_Refresh_EN_4_56:
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/partners/purolator/Ship_Vista_Page_Refresh_EN_4_56.png",
};

export const helpImages = {
  "import-orders-store-click-import":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-import-icon.png",
  "import-orders-store-click-update-all":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-update-all.png",
  "click-orders-tab":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-orders-tab.png",
  "create-manual-orders-click-cmo-button":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-cmo-button.png",
  "create-manual-orders-fill-in-info":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/fill-in-info.png",
  "create-manual-orders-dropdown-stores":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/dropdown-stores.png",
  "create-manual-orders-add-line-items":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/add-line-items.png",
  "create-manual-orders-two-options":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/two-options.png",
  "view-orders-basic-search":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/basic-search.png",
  "view-orders-advanced-search-one":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/advanced-search-one.png",
  "view-orders-advanced-search-two":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/advanced-search-two.png",
  "view-orders-sort-general":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/sort-general.png",
  "view-orders-sort-specific":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/sort-specific.png",
  "view-orders-multi-items-view":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/multi-items-view.png",
  "filter-orders-filters-general":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/filters-general.png",
  "filter-orders-filters-specific":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/filters-specific.png",
  "filter-orders-filters-general-remove":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/filters-general-remove.png",
  "filter-orders-filters-specific-remove":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/filters-specific-remove.png",
  "hold-cancel-orders-select-orders-hold":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/select-orders-hold.png",
  "hold-cancel-orders-click-hold-button":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-hold-button.png",
  "hold-cancel-orders-hold-orders-dialog":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/hold-orders-dialog.png",
  "hold-cancel-orders-hold-until-click":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/hold-until-click.png",
  "hold-cancel-orders-hold-until-calendar":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/hold-until-calendar.png",
  "hold-cancel-orders-click-hold-orders-button":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-hold-orders-button.png",
  "hold-cancel-orders-navigate-on-hold":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/navigate-on-hold.png",
  "hold-cancel-orders-select-orders-restore-hold":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/select-orders-restore-hold.png",
  "hold-cancel-orders-click-restore-hold-button":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-restore-hold-button.png",
  "hold-cancel-orders-restore-hold-popover":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/restore-hold-popover.png",
  "hold-cancel-orders-click-cancel-button":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-cancel-button.png",
  "hold-cancel-orders-cancel-popover":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/cancel-popover.png",
  "hold-cancel-orders-select-orders-restore-cancel":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/select-orders-restore-cancel.png",
  "hold-cancel-orders-click-restore-cancel-button":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-restore-cancel-button.png",
  "hold-cancel-orders-restore-cancel-popover":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/restore-cancel-popover.png",
  "split-orders-select-orders-split":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/select-orders-split.png",
  "split-orders-select-split":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/select-split.png",
  "split-orders-split-dialog":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/split-dialog.png",
  "split-orders-split-items":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/split-items.png",
  "split-orders-split-calculated":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/split-calculated.png",
  "split-orders-split-paid":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/split-paid.png",
  "split-orders-split-button":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/split-button.png",
  "combine-orders-select-orders-combine":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/select-orders-combine.png",
  "combine-orders-click-combine-orders":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-combine-orders.png",
  "combine-orders-combine-dialog":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/combine-dialog.png",
  "combine-orders-combine-drag":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/combine-drag.png",
  "combine-orders-click-combine":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-combine.png",
  "order-tags-select-order-tags":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/select-order-tags.png",
  "order-tags-click-tags":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-tags.png",
  "order-tags-manage-tags-dialog":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/manage-tags-dialog.png",
  "order-tags-click-new-tag":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-new-tag.png",
  "order-tags-new-tag-input":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/new-tag-input.png",
  "order-tags-new-tag-input-color":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/new-tag-input-color.png",
  "order-tags-new-tag-save":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/new-tag-save.png",
  "order-tags-click-edit-tag":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-edit-tag.png",
  "order-tags-save-edit-tag":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/save-edit-tag.png",
  "order-tags-delete-tag":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/delete-tag.png",
  "order-tags-select-manage-tags":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/select-manage-tags.png",
  "order-tags-tag-full-mark":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/tag-full-mark.png",
  "order-tags-tag-partial-mark":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/tag-partial-mark.png",
  "order-tags-tag-no-mark":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/tag-no-mark.png",
  "order-tags-click-tags-dropdown":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-tags-dropdown.png",
  "order-tags-click-manage-tags":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-manage-tags.png",
  "edit-order-details-open-order-details":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/open-order-details.png",
  "edit-order-details-order-details-dialog":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/order-details-dialog.png",
  "edit-order-details-edit-address-button":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/edit-address-button.png",
  "edit-order-details-editing-address":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/editing-address.png",
  "edit-order-details-edit-address-save":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/edit-address-save.png",
  "edit-order-details-start-edit-internal-notes":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/start-edit-internal-notes.png",
  "edit-order-details-editing-internal-notes":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/editing-internal-notes.png",
  "edit-order-details-end-edit-internal-notes":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/end-edit-internal-notes.png",
  "edit-order-details-order-activity-section":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/order-activity-section.png",
  "edit-services-navigate-settings":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/navigate-settings.png",
  "edit-services-navigate-carrier-setup":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/navigate-carrier-setup.png",
  "edit-services-carrier-two":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/carrier-two.png",
  "edit-services-carrier-preview-button":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/carrier-preview-button.png",
  "edit-services-carrier-edit-button":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/carrier-edit-button.png",
  "edit-services-carrier-edit-settings":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/carrier-edit-settings.png",
  "edit-services-edited-services":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/edited-services.png",
  "edit-services-update-button-services":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/update-button-services.png",
  "configure-shipping-update-shipping-info":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/update-shipping-info.png",
  "configure-shipping-set-ship-from-address":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/set-ship-from-address.png",
  "configure-shipping-set-package-type":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/set-package-type.png",
  "configure-shipping-set-dimensions":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/set-dimensions.png",
  "configure-shipping-choose-service":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/choose-service.png",
  "configure-shipping-choose-service-options":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/choose-service-options.png",
  "configure-shipping-choose-insurance":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/choose-insurance.png",
  "configure-shipping-select-multiple-orders-sidebar":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/select-multiple-orders-sidebar.png",
  "configure-shipping-bulk-update-section":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/bulk-update-section.png",
  "configure-shipping-update-selected-orders":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/update-selected-orders.png",
  "shipping-presets-orders-sidebar-details":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/orders-sidebar-details.png",
  "shipping-presets-click-apply-preset-single":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-apply-preset-single.png",
  "shipping-presets-existing-presets-list":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/existing-presets-list.png",
  "shipping-presets-manage-presets-dialog":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/manage-presets-dialog.png",
  "shipping-presets-click-new-preset":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-new-preset.png",
  "shipping-presets-fill-in-preset":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/fill-in-preset.png",
  "shipping-presets-click-add-preset":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-add-preset.png",
  "shipping-presets-apply-preset-single":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/apply-preset-single.png",
  "shipping-presets-orders-sidebar-details-multiple":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/orders-sidebar-details-multiple.png",
  "shipping-presets-preset-list":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/preset-list.png",
  "shipping-presets-preset-applied-multiple":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/preset-applied-multiple.png",
  "shipping-presets-update-selected-orders-preset":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/update-selected-orders-preset.png",
  "add-funds-expand-account-panel":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/expand-account-panel.png",
  "add-funds-click-payments":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-payments.png",
  "add-funds-payments-page":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/payments-page.png",
  "add-funds-click-add-funds":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-add-funds.png",
  "add-funds-click-add-funds-button":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-add-funds-button.png",
  "add-funds-add-funds-dialog":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/add-funds-dialog.png",
  "add-funds-add-funds-dialog-filled":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/add-funds-dialog-filled.png",
  "add-funds-add-funds-submit":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/add-funds-submit.png",
  "add-funds-click-username-dropdown":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-username-dropdown.png",
  "add-funds-click-add-funds-dropdown":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-add-funds-dropdown.png",
  "add-card-click-add-card-payments":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-add-card-payments.png",
  "add-card-add-card-dialog":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/add-card-dialog.png",
  "add-card-click-save-card":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-save-card.png",
  "add-card-click-add-card-dialog":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-add-card-dialog.png",
  "track-shipment-navigate-shipments":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/navigate-shipments.png",
  "track-shipment-click-shipment-history":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-shipment-history.png",
  "track-shipment-shipment-history-table":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/shipment-history-table.png",
  "track-shipment-click-tracking-number":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-tracking-number.png",
  "setting-overview-settings-sidebar":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/settings-sidebar.png",
  "setting-overview-settings-sidebar-expanded":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/settings-sidebar-expanded.png",
  "setting-overview-settings-sidebar-page-active":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/settings-sidebar-page-active.png",
  "setting-overview-username-dropdown-displayed":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/username-dropdown-displayed.png",
  "setting-overview-username-dropdown-tabs":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/username-dropdown-tabs.png",
  "setting-overview-click-integrations-tab":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-integrations-tab.png",
  "setting-overview-username-dropdown-carriers":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/username-dropdown-carriers.png",
  "my-profile-my-profile-page":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/my-profile-page.png",
  "my-profile-my-profile-contact":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/my-profile-contact.png",
  "my-profile-my-profile-address":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/my-profile-address.png",
  "my-profile-my-profile-update":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/my-profile-update.png",
  "my-profile-click-change-password":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-change-password.png",
  "my-profile-change-password-dialog":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/change-password-dialog.png",
  "my-profile-change-password-dialog-filled":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/change-password-dialog-filled.png",
  "my-profile-change-password-update":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/change-password-update.png",
  "my-profile-click-login-button":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-login-button.png",
  "my-profile-click-forgot-password":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-forgot-password.png",
  "my-profile-forgot-password-filled":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/forgot-password-filled.png",
  "transaction-history-transaction-history-page":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/transaction-history-page.png",
  "transaction-history-transaction-table":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/transaction-table.png",
  "transaction-history-transaction-search":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/transaction-search.png",
  "transaction-history-transaction-filter":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/transaction-filter.png",
  "transaction-history-transaction-clear-filter":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/transaction-clear-filter.png",
  "transaction-history-transaction-balance":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/transaction-balance.png",
  "transaction-history-transaction-page-controls":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/transaction-page-controls.png",
  "subscription-subscription-page":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/subscription-page.png",
  "subscription-subscription-your-plan":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/subscription-your-plan.png",
  "subscription-change-plan-button":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/change-plan-button.png",
  "subscription-change-plan-dialog":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/change-plan-dialog.png",
  "subscription-change-plan-ends":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/change-plan-ends.png",
  "subscription-upgrade-plan-dialog":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/upgrade-plan-dialog.png",
  "subscription-promo-filled":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/promo-filled.png",
  "subscription-click-change-plan":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-change-plan.png",
  "subscription-subscription-billing-information":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/subscription-billing-information.png",
  "subscription-click-change-billing":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-change-billing.png",
  "subscription-change-billing-dialog":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/change-billing-dialog.png",
  "subscription-click-change-for-billing":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-change-for-billing.png",
  "batch-navigate-awaiting-shipments":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/navigate-awaiting-shipments.png",
  "batch-create-label-shipping-errors":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/create-label-shipping-errors.png",
  "batch-error-order-summary-dialog":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/error-order-summary-dialog.png",
  "batch-click-order-id-details":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-order-id-details.png",
  "batch-bulk-update-orders":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/bulk-update-orders.png",
  "batch-click-update-selected-orders-filled":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-update-selected-orders-filled.png",
  "batch-create-label-no-errors":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/create-label-no-errors.png",
  "batch-create-batch-label-dialog":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/create-batch-label-dialog.png",
  "batch-batch-dialog-input":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/batch-dialog-input.png",
  "batch-batch-dialog-button":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/batch-dialog-button.png",
  "batch-batch-dialog-processing":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/batch-dialog-processing.png",
  "batch-batch-dialog-failed":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/batch-dialog-failed.png",
  "mark-shipped-click-mark-as-shipped":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-mark-as-shipped.png",
  "mark-shipped-mark-as-shipped-dialog":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/mark-as-shipped-dialog.png",
  "mark-shipped-mark-as-shipped-filled":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/mark-as-shipped-filled.png",
  "mark-shipped-mark-as-shipped-date":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/mark-as-shipped-date.png",
  "mark-shipped-mark-as-shipped-button":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/mark-as-shipped-button.png",
  "end-day-navigate-to-shipments":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/navigate-to-shipments.png",
  "end-day-click-end-day-panel":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-end-day-panel.png",
  "end-day-open-shipments-table":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/open-shipments-table.png",
  "end-day-open-shipments-date":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/open-shipments-date.png",
  "end-day-open-shipments-table-column":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/open-shipments-table-column.png",
  "end-day-click-close-shipments":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-close-shipments.png",
  "end-day-close-shipments-dialog":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/close-shipments-dialog.png",
  "end-day-close-shipments-table":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/close-shipments-table.png",
  "end-day-close-shipments-all-check-mark":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/close-shipments-all-checkmark.png",
  "end-day-close-shipments-buttons":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/close-shipments-buttons.png",
  "end-day-close-shipment-tab":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/close-shipment-tab.png",
  "end-day-closed-shipments-page":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/closed-shipments-page.png",
  "end-day-close-shipment-tab-table":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/close-shipment-tab-table.png",
  "end-day-click-print-selected":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-print-selected.png",
  "end-day-close-shipment-page-controls":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/close-shipment-page-controls.png",
  "end-day-close-shipment-tab-active":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/close-shipment-tab-active.png",
  "connect-store-settings-cog":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/settings-cog.png",
  "connect-store-click-intergrations":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-intergrations.png",
  "connect-store-select-store-setup":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/select-store-setup.png",
  "connect-store-connect-store-button":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/connect-store-button.png",
  "connect-store-select-woo":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/select-woo.png",
  "connect-store-enter-details":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/enter-details.png",
  "connect-store-login-as-admin":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/login-as-admin.png",
  "connect-store-woo-permission-page":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/woo-permission-page.png",
  "connect-store-store-added-success":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/store-added-success.png",
  "create-manual-store-search-shipvista-optional":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/search-shipvista-optional.png",
  "create-manual-store-select-shipvista-logo":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/select-shipvista-logo.png",
  "create-manual-store-input-and-connect":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/input-and-connect.png",
  "deactivate-store-click-deactivate-switch":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-deactivate-switch.png",
  "edit-store-settings-click-edit-icon":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-edit-icon.png",
  "edit-store-settings-enter-new-nickname":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/enter-new-nickname.png",
  "edit-store-settings-check-new-nickname":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/check-new-nickname.png",
  "edit-store-settings-nickname-saved":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/nickname-saved.png",
  "address-book-select-settings":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/select-settings.png",
  "address-book-select-shipping":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/select-shipping.png",
  "address-book-select-address-book":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/select-address-book.png",
  "address-book-click-add-address":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-add-address.png",
  "address-book-enter-address-details":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/enter-address-details.png",
  "address-book-click-save":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-save.png",
  "address-book-address-added":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/address-added.png",
  "getting-rates-select-shipments-menu":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/select-shipments-menu.png",
  "getting-rates-select-create-shipment":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/select-create-shipment.png",
  "getting-rates-enter-ship-from-address":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/enter-ship-from-address.png",
  "getting-rates-enter-ship-to-address":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/enter-ship-to-address.png",
  "getting-rates-enter-shipping-items":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/enter-shipping-items.png",
  "getting-rates-click-get-rates":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-get-rates.png",
  "getting-rates-rates-appear":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/rates-appear.png",
  "create-label-shipments-select-preffered-rate":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/select-preffered-rate.png",
  "create-label-shipments-shipment-details-dialog":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/shipment-details-dialog.png",
  "create-label-shipments-shipments-details-zoomed":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/shipments-details-zoomed.png",
  "create-label-shipments-address-details":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/address-details.png",
  "create-label-shipments-shipping-items-details":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/shipping-items-details.png",
  "create-label-shipments-service-and-rate-details":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/service-and-rate-details.png",
  "create-label-shipments-add-ons-select":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/add-ons-select.png",
  "create-label-shipments-add-ons-added":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/add-ons-added.png",
  "create-label-shipments-customer-reference-no-filled":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/customer-reference-no-filled.png",
  "create-label-shipments-paper-size-select":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/paper-size-select.png",
  "create-label-shipments-click-create-label":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/click-create-label.png",
  "create-label-shipments-shipping-label-dialog":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/shipping-label-dialog.png",
  "create-label-shipments-shipping-label":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/shipping-label.png",
  "create-label-shipments-shipments-details":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/shipments-details.png",
  "connect-carrier-select-carrier-setup":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/select-carrier-setup.png",
  "connect-carrier-carrier-connect-button":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/carrier-connect-button.png",
  "connect-carrier-carrier-list":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/carrier-list.png",
  "connect-carrier-ups-connect":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/ups-connect.png",
  "airmiles-step-1":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/air-miles/open-username-dropdown-2.png",
  "airmiles-step-2":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/air-miles/click-air-miles-2.png",
  "airmiles-step-3":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/air-miles/air-miles-page-2.png",
  "airmiles-step-4":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/air-miles/air-miles-card-unfilled-2.png",
  "airmiles-step-5":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/air-miles/click-link-account-2.png",
  "airmiles-step-6":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/air-miles/enter-details-2.png",
  "airmiles-step-7":
    "https://cbpstaticfiles.blob.core.windows.net/sv-app/help-images/air-miles/air-miled-card-added-2.png",
};

//insureshield

export const insureShieldImg1 =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/insureshield/ups-laptop.png";
export const insureShieldImg2 =
  "https://cbpstaticfiles.blob.core.windows.net/sv-app/site-images/insureshield/ups-capital-logo.jpg";
