export const routes = {
  home: "/",
  dashboard: "/dashboard",
  getQuote: "/get-quote",
  technologyPartner: "/partners/technology",
  partners: "/partners",
  fulfillmentPartners: "/partners/fulfillment",
  howItWorks: "/how-it-works",
  otherCarriers: "/carriers",
  developers: "/developers/overview",
  register: "/register",
  resetPassword: "/reset-password",
  login: "/login",
  trackShipment: "/track-shipment",
  blog: "/blog",
  contact: "/contact/support",
  about: "/about",
  contestAirmilesGiveaway: "/contests/airmiles-giveaway",
  openAccountOffer: "/open-account-offer",
  helpConnect: "/help/shipvista-help/manage-orders/connect-a-store",
  helpIntegrations:
    "/help/integrations/store-integrations/store-integrations-overview",
  helpWorkFlow: "/help/workflows/user-settings/settings-overview",
  helpGuideFaq: "help/guides/balance/add-funds",
  connectTools: "/info/connect-tools",
  documentToolkit: "/document-toolkit",
  quickVideos: "/quick-videos",
  features: "/features",
  apiStatus: "/developers/api/status",
  carriers: "/carriers",
  becomeAPartner: "/become-a-partner",
  becomeAnAffiliate: "/become-an-affiliate",
  becomeADeveloper: "/developers/become-a-developer",
  careers: "/careers/jobs",
  newsroom: "/media/newsroom",
  terms: "/terms-of-use",
  privacyPolicy: "/privacy-policy",
  help: "/help",
  contactMain: "/contact",
  creditCardConsentAgreement: "/credit-card-consent-agreement",
  commissionProgramAgreement: "/commission-program-agreement",
  sitemap: "/sitemap.xml",
  linkUps: "/partners/ups/link-your-ups-account",
  pricing: "/pricing",
  airmiles: "/airmiles",
  insureshield: "/insureshield",
  personalInfoForm: "/personal-info-form",
  contests: "/contests",
  airmilesGiveAway: "/contests/airmiles-giveaway",
  contactSales: "/contact/sales",
  forgotPassword: "/forgot-password",
  cancelShipmentUps: "/partners/ups/cancel-shipment",
  upsReadyProgram: "/partners/ups/ready-program",
  purolatorShipNow: "/partners/purolator/ship-now",
  purolatorSpecializedServices: "/partners/purolator/specialized-services",
  purolatorTackleHunger: "https://www.purolator.com/en/purolator-tackle-hunger",
  purolatorEnvironmentSustainability:
    "https://www.purolator.com/en/about-purolator/our-commitment-environmental-sustainability",
};
